html {
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
	width: 100%;
	height: 100%;
	scroll-behavior: auto !important;
	scrollbar-color: $dark-600 #dee2e6;
	scrollbar-width: thin;
}

input[type="submit"] {
	-webkit-appearance: none;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	scrollbar-width: thin;
	&:after {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
	}

	&:before {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
	}
}

body {
	margin: 0;
	padding: 0;
	font-family: $font-family-sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	width: 100%;
	height: 100%;
	font-weight: 400;
	color: $body-color;
	scrollbar-color: $gray-10 $gray-5;
	scrollbar-width: thin;
}

a {
	outline: none;
	text-decoration: none;
	color: inherit;
	&:hover {
		text-decoration: none;
		outline: none;
		outline-offset: 0;
		color: inherit;
	}
	&:focus {
		text-decoration: none;
		outline: none;
		outline-offset: 0;
		color: inherit;
	}
}

img {
	border: 0;
	max-width: 100%;
	height: auto;
}

input {
	outline: none;
	resize: none;
	box-shadow: none;
	font-family: $font-family-sans-serif;
}

textarea {
	outline: none;
	resize: none;
	box-shadow: none;
	font-family: $font-family-sans-serif;
}

select {
	outline: none;
	resize: none;
	box-shadow: none;
	font-family: $font-family-sans-serif;
}

strong {
	font-weight: 700;
}
button {
	box-shadow: none;
	&::-moz-focus-inner {
		border: 0;
	}
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.2;
	margin: 0;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

p {
	margin: 0 0 10px 0;
	padding: 0;
}
button:focus {
	outline: none;
}
::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	@include border-radius(4px);
}
::-webkit-scrollbar-track {
	background: $gray-5;
	@include border-radius(4px);
}
::-webkit-scrollbar-thumb {
	background: $gray-10;
	min-height: 100px;
	@include border-radius(4px);
}
::-webkit-scrollbar-thumb:window-inactive {
	background: $dark-600;
}
.svg {
	path {
		@include transition(all 0.3s ease-in-out);
	}
}

.container {
	max-width: 1300px;
	padding-left: 15px;
	padding-right: 15px;
}

.badge {
	font-weight: 500;
	padding: 0.75em 1em;
}

.secondary-font {
	font-family: $font-family-secondary;
}
.primary-font {
	font-family: $font-family-sans-serif;
}

#root {
	min-height: 100%;
}

.tick-list {
	ul {
		li {
			padding-left: 30px;
			position: relative;
			margin-bottom: 12px;
			&:before {
				content: "";
				width: 7px;
				height: 14px;
				@include transform-rotate(45deg);
				border-bottom: 2px solid $primary;
				border-right: 2px solid $primary;
				position: absolute;
				left: 5px;
				top: 4px;
				display: block;
			}
		}
	}
}
.number-list {
	ol {
		counter-reset: item 0;
		li {
			padding-left: 30px;
			position: relative;
			margin-bottom: 12px;
			&:before {
				content: counters(item, ".") ". ";
				counter-increment: item 1;
				position: absolute;
				left: 0;
				top: 0px;
				font-weight: 600;
				display: block;
			}
			ol {
				margin-top: 12px;
				li {
					padding-left: 0;
					&:before {
						position: relative;
						display: inline;
					}
				}
				ol {
					padding-left: 20px;
				}
			}
		}
	}
}

[data-scrollbar] {
	overflow: auto;
}
.flag {
	@include border-radius(3px);
}
html.theme-transition,
html.theme-transition *,
html.theme-transition *:before,
html.theme-transition *:after {
	// @include transition(all 0.3s ease-in-out 0);
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

// max width mixin
$max-width-map: (
	50: 50px,
	60: 60px,
	80: 80px,
	100: 100px,
	150: 150px,
	200: 200px,
	250: 250px,
	300: 300px,
	350: 350px,
	400: 400px,
	450: 450px,
	500: 500px,
	550: 550px,
	600: 600px,
	650: 650px,
	700: 700px,
	750: 750px,
	800: 800px,
	850: 850px,
	900: 900px,
	950: 950px,
	1000: 1000px,
	1050: 1050px,
);

@each $name, $width in $max-width-map {
	.mx-w-#{$name} {
		max-width: $width;
	}
	.w-#{$name} {
		width: $width;
	}
	.h-#{$name} {
		height: $width;
	}
	.mx-h-#{$name} {
		max-height: $width;
	}
}

// opacity mixin
$opacity-map: (
	0: 0,
	05: 0.05,
	10: 0.1,
	15: 0.15,
	20: 0.2,
	25: 0.25,
	30: 0.3,
	35: 0.35,
	40: 0.4,
	45: 0.45,
	50: 0.5,
	55: 0.55,
	60: 0.6,
	65: 0.65,
	70: 0.7,
	80: 0.8,
	85: 0.85,
	90: 0.9,
	95: 0.95,
	100: 1,
);

@each $name, $opacity in $opacity-map {
	.opacity-#{$name} {
		opacity: $opacity;
	}
}

.bg-gray {
	background: #f8f8f8;
}

.page-loader {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: $black;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1234;
}


.insta-section .global-office-list{padding-bottom: 32%;position: relative;}
.insta-section{margin-bottom: 50px;}
.insta-section iframe{width: 100%;height: 100%;position: absolute;top: 0;left: 0;}

@media screen and (max-width: 510px){
	.insta-section .global-office-list{padding-bottom: 94%;}
}

.inner-watermark{display: none;}
.global-office-list{position: relative;}
.global-office-list:after{content: "";
	content: "";
	position: absolute;
	height: 20px;
	background: #fff;
	bottom: 0;
	right: 17px;
	left: 0;}


