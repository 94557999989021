.form-check {
	padding-left: 30px;
	min-height: 20px;
	.form-check-input {
		margin-left: -30px;
	}
	&.form-switch {
		padding-left: 50px;
		.form-check-input {
			margin-left: -50px;
			width: 40px;
			&[type="checkbox"] {
				@include border-radius(20px);
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
				&:checked {
					background-position: right center;
					background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
					&:disabled {
						background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
					}
				}
			}
		}
	}
}
.form-check-input,
.bootstrap-table input[type="checkbox"] {
	width: 20px;
	height: 20px;
	border-color: $input-border-color;
	margin-top: 0;
	box-shadow: none;
	background-position: center center;
	@include border-radius(4px);
	@include transition(all 0.3s ease-in-out);
	&:hover {
		border-color: $primary;
		background-color: rgba($primary, 0.1);
	}
	&:checked {
		&[type="checkbox"] {
			background-color: $primary;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 24 24' viewBox='0 0 24 24'%3E%3Cpath d='M18.7,7.2c-0.4-0.4-1-0.4-1.4,0l-7.5,7.5l-3.1-3.1c0,0,0,0,0,0c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l3.8,3.8c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l8.2-8.2C19.1,8.2,19.1,7.6,18.7,7.2z' fill='%23ffffff' /%3E%3C/svg%3E");
			background-size: 20px 20px;
			&:hover {
				background-color: $primary;
			}
		}
		&[type="radio"] {
			background-color: $primary;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 24 24' viewBox='0 0 24 24'%3E%3Cpath d='M18.7,7.2c-0.4-0.4-1-0.4-1.4,0l-7.5,7.5l-3.1-3.1c0,0,0,0,0,0c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l3.8,3.8c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l8.2-8.2C19.1,8.2,19.1,7.6,18.7,7.2z' fill='%23ffffff' /%3E%3C/svg%3E");
			background-size: 20px 20px;
			&:hover {
				background-color: $primary;
			}
		}
		&:focus {
			border-color: $primary;
		}
	}
	&:disabled {
		opacity: 0.9;
		background-color: $input-group-addon-bg !important;
		border-color: $input-border-color !important;
		&:checked {
			&[type="checkbox"] {
				background-color: $primary;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 24 24' viewBox='0 0 24 24'%3E%3Cpath d='M18.7,7.2c-0.4-0.4-1-0.4-1.4,0l-7.5,7.5l-3.1-3.1c0,0,0,0,0,0c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l3.8,3.8c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l8.2-8.2C19.1,8.2,19.1,7.6,18.7,7.2z' fill='%237c7c7c' /%3E%3C/svg%3E");
				background-size: 20px 20px;
			}
			&[type="radio"] {
				background-color: $primary;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 24 24' viewBox='0 0 24 24'%3E%3Cpath d='M18.7,7.2c-0.4-0.4-1-0.4-1.4,0l-7.5,7.5l-3.1-3.1c0,0,0,0,0,0c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l3.8,3.8c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l8.2-8.2C19.1,8.2,19.1,7.6,18.7,7.2z' fill='%239e9e9e' /%3E%3C/svg%3E");
				background-size: 20px 20px;
			}
		}
	}
	&:focus {
		box-shadow: none;
		border-color: $primary;
		&:hover {
			box-shadow: none;
		}
	}
	&.secondary-check-input {
		&:hover {
			border-color: $secondary;
			background-color: rgba($secondary, 0.1);
		}
		&:checked {
			background-color: $secondary;
			border-color: $secondary;
			&:hover {
				background-color: $secondary;
			}
			&:focus {
				border-color: $secondary;
			}
		}
		&:focus {
			box-shadow: none;
			border-color: $secondary;
		}
	}
	&.success-check-input {
		&:hover {
			border-color: $success;
			background-color: rgba($success, 0.1);
		}
		&:checked {
			background-color: $success;
			border-color: $success;
			&:hover {
				background-color: $success;
			}
			&:focus {
				border-color: $success;
			}
		}
		&:focus {
			box-shadow: none;
			border-color: $success;
		}
	}
	&.danger-check-input {
		&:hover {
			border-color: $danger;
			background-color: rgba($danger, 0.1);
		}
		&:checked {
			background-color: $danger;
			border-color: $danger;
			&:hover {
				background-color: $danger;
			}
			&:focus {
				border-color: $danger;
			}
		}
		&:focus {
			box-shadow: none;
			border-color: $danger;
		}
	}
	&.warning-check-input {
		&:hover {
			border-color: $warning;
			background-color: rgba($warning, 0.1);
		}
		&:checked {
			background-color: $warning;
			border-color: $warning;
			&:hover {
				background-color: $warning;
			}
			&:focus {
				border-color: $warning;
			}
		}
		&:focus {
			box-shadow: none;
			border-color: $warning;
		}
	}
	&.info-check-input {
		&:hover {
			border-color: $info;
			background-color: rgba($info, 0.1);
		}
		&:checked {
			background-color: $info;
			border-color: $info;
			&:hover {
				background-color: $info;
			}
			&:focus {
				border-color: $info;
			}
		}
		&:focus {
			box-shadow: none;
			border-color: $info;
		}
	}
	&.dark-check-input {
		&:hover {
			border-color: $dark;
			background-color: rgba($dark, 0.1);
		}
		&:checked {
			background-color: $dark;
			border-color: $dark;
			&:hover {
				background-color: $dark;
			}
			&:focus {
				border-color: $dark;
			}
		}
		&:focus {
			box-shadow: none;
			border-color: $dark;
		}
	}
	&.skyblue-check-input {
		&:hover {
			border-color: $skyblue;
			@include box-shadow(inset 0 0 0 1px $skyblue);
		}
		&:checked {
			background-color: $skyblue;
			border-color: $skyblue;
			&:focus {
				border-color: $skyblue;
			}
		}
		&:focus {
			box-shadow: none;
			border-color: $input-border-color;
			&:hover {
				@include box-shadow(inset 0 0 0 1px $skyblue);
			}
		}
	}
	&[type="checkbox"] {
		@include border-radius(4px);
	}
}
.form-check-label {
	font-size: 14px;
	line-height: 20px;
	color: $gray-700;
	vertical-align: top;
	a {
		color: $secondary;
		font-weight: 700;
	}
}

.inline-check-list {
	display: flex;
	flex-wrap: wrap;
	.form-check {
		margin-right: 20px;
		margin-top: 5px;
		margin-bottom: 5px;
		&:last-child {
			margin-right: 0;
		}
	}
}

.bootstrap-table input[type="checkbox"] {
	border-width: 1px;
	border-style: solid;
	vertical-align: top;
	background-color: #fff;
	background-repeat: no-repeat;
	background-size: contain;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	&:checked {
		border-color: $primary;
	}
}
