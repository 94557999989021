.css-generator-area {
	display: flex;
	flex-wrap: wrap;
	min-height: calc(100vh - 162px);
	.generator-sidebar-area {
		width: 320px;
		padding: 16px 0 16px 16px;
		position: relative;
		@include breakpoint(max, 767) {
			width: 100%;
			padding: 16px 20px;
		}
		@include breakpoint(min, 768) {
			.sidebar-toggle {
				display: none;
			}
			.collapse:not(.show) {
				display: block;
			}
		}
	}
	.generator-preview-area {
		width: calc(100% - 320px);
		padding: 16px 24px;
		@include breakpoint(max, 767) {
			width: 100%;
			padding: 16px 20px;
		}
	}
}

.generator-area {
	padding-top: 48px;
	display: flex;
	flex-wrap: wrap;
	.generator-area-options {
		width: 40%;
		&.half-width {
			width: 50%;
			@include breakpoint(max, 1199) {
				width: 100%;
			}
		}
		@include breakpoint(max, 1199) {
			width: 100%;
		}
	}
	.generator-area-preview {
		width: 60%;
		padding-left: 24px;
		&.half-width {
			width: 50%;
			@include breakpoint(max, 1199) {
				width: 100%;
			}
		}
		&.full-width {
			width: 100%;
			padding-left: 0;
			@include breakpoint(max, 1199) {
				width: 100%;
			}
		}
		@include breakpoint(max, 1199) {
			width: 100%;
			padding-left: 0;
			padding-top: 24px;
		}
	}
}
.code-preview-box {
	margin: 0 !important;
	user-select: all;
	code {
		padding: 0 !important;
	}
}
.code-preview {
	position: relative;
	padding-right: 10px;
}
.copycode-btn {
	width: 42px;
	height: 42px;
	border: 0;
	background: rgba($primary, 0.2);
	color: $primary;
	display: flex;
	align-items: center;
	justify-content: center;
	@include border-radius(4px);
}

.radiobutton-dd {
	position: relative;
	padding-bottom: 2px;
	input {
		position: absolute;
		left: 0;
		opacity: 0;
		&:checked ~ label {
			color: $white;
			background: $primary;
		}
	}
	label {
		padding: 6px 12px;
		display: flex;
		align-items: center;
		color: $dark;
		font-size: 15px;
		line-height: 20px;
		white-space: nowrap;
		cursor: pointer;
		@include border-radius(4px);
		@include transition(all 0.3s ease-in-out);
		&:hover {
			color: $white;
			background: $primary;
		}
		svg {
			flex-shrink: 0;
			margin-right: 8px;
		}
	}
}
