@import "../../variables";
@import "../../mixins";

.header-wrap {
	position: relative;
	height: 100px;
	@include transition(height 0.3s linear);
	.header {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100px;
		background: $white;
		z-index: 123;
		@include transition(all 0.3s linear);
		.container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 100%;
		}
		.logo {
			@include breakpoint(max, 1100) {
				max-width: 130px;
			}
			a {
				display: inline-block;
			}
		}
	}
	&.shrink {
		.header {
			@include box-shadow(0px 3px 6px rgba($black, 0.29));
		}
	}
	.sidebar-toggle {
		width: 30px;
		height: 30px;
		z-index: 1;
		border: 0;
		background: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
		@include breakpoint(min, 1101) {
			display: none;
		}
	}
	.e-shop-mobile {
		display: none;
		@include breakpoint(max, 545) {
			border: 2px solid #BED02C;
			display: block;
			margin-left: 70px;
			padding: 5px;
			color: #2874B9;
			border-radius: 4px;
		}
	}
}

@include breakpoint(min, 1100) {
	.investors-menu {
		column-count: 2;
	}
}

.menu-wrap {
	display: flex;
	align-items: center;
	@include breakpoint(max, 1100) {
		position: fixed;
		width: 100%;
		max-width: 360px;
		height: 100vh;
		right: -100%;
		top: 0;
		background: rgba($white, 1);
		z-index: 123;
		flex-direction: column-reverse;
		@include transition(all 0.3s ease-in-out);
		&.menu-show {
			right: 0;
		}
	}
	@include breakpoint(max, 600) {
		max-width: 100%;
	}
	.sidebar-toggle {
		position: absolute;
		right: 8px;
		top: 6px;
	}
	.language-cart {
		display: flex;
		align-items: center;
		@include breakpoint(max, 1100) {
			padding-right: 46px;
			width: 100%;
		}
		svg {
			width: 20px;
			height: 20px;
		}
		.language-dd {
			padding: 0 20px;
			position: relative;
			@include breakpoint(max, 1100) {
				padding: 0;
				width: 50%;
			}
			> button {
				border: 0;
				background: transparent;
				color: $black;
				font-size: 16px;
				line-height: 1;
				text-transform: uppercase;
				display: flex;
				align-items: center;
				cursor: pointer;
				@extend .transition;
				@include breakpoint(min, 1101) {
					pointer-events: none;
				}
				@include breakpoint(max, 1400) {
					font-size: 18px;
				}
				@include breakpoint(max, 1100) {
					background: $primary;
					color: $white;
					width: 100%;
					padding: 10px 6px;
					justify-content: center;
				}
				svg {
					flex-shrink: 0;
					margin-right: 6px;
				}
				&:hover {
					@include breakpoint(min, 1101) {
						color: $primary;
					}
				}
			}
			.sub-menu {
				position: absolute;
				left: 0;
				top: 100%;
				background: $white;
				margin-top: 20px;
				opacity: 0;
				visibility: hidden;
				min-width: 100px;
				text-align: center;
				@include box-shadow(0px 0px 15px rgba($black, 0.1));
				@include breakpoint(max, 1100) {
					// position: relative;
					// top: 0;
					left: 0;
					right: 0;
					margin: 0 auto;
					// box-shadow: none;
					// padding: 0 15px;
					opacity: 1;
					visibility: visible;
				}
				&:before {
					content: "";
					width: 100%;
					height: 20px;
					top: -20px;
					position: absolute;
					left: 0;
					@include breakpoint(max, 1100) {
						display: none;
					}
				}
				&.collapse:not(.show) {
					@include breakpoint(min, 1101) {
						display: block;
						// opacity: 0;
						// visibility: hidden;
					}
				}
				@include breakpoint(min, 1101) {
					@include transition(all 0.3s ease-in-out);
				}
				ul {
					padding: 8px 0;
					@include breakpoint(max, 1100) {
						padding-top: 0;
					}
					li {
						a {
							display: block;
							padding: 8px 20px;
							color: $black;
							white-space: nowrap;
							@extend .transition;
							@include breakpoint(max, 1100) {
								font-size: 15px;
							}
							&:hover {
								color: $primary;
							}
						}
					}
				}
			}
			&:hover {
				.sub-menu {
					opacity: 1;
					visibility: visible;
				}
			}
		}
		.header-cart {
			padding-left: 20px;
			@include breakpoint(max, 1100) {
				padding: 0;
				width: 50%;
			}
			> button,
			> a {
				border: 0;
				background: transparent;
				color: $black;
				display: flex;
				align-items: center;
				@extend .transition;
				@include breakpoint(max, 1100) {
					background: $secondary;
					color: $white;
					width: 100%;
					padding: 10px 6px;
					justify-content: center;
				}
				svg {
					flex-shrink: 0;
					margin-right: 6px;
				}
				&:hover {
					color: $primary;
				}
			}
		}
	}
	.menu {
		@include breakpoint(max, 1100) {
			height: 100%;
			padding: 12px 0;
			width: 100%;
			overflow: auto;
		}
		> ul {
			display: flex;
			flex-wrap: wrap;
			@include breakpoint(max, 1100) {
				display: block;
			}
			> li {
				padding: 0 20px;
				position: relative;
				@include breakpoint(max, 1100) {
					padding: 0;
				}
				> a {
					display: inline-block;
					color: $black;
					font-size: 16px;
					text-transform: uppercase;
					position: relative;
					@extend .transition;
					@include breakpoint(max, 1100) {
						display: block;
						padding: 12px 50px 12px 24px;
					}
					&:before {
						content: "";
						position: absolute;
						left: 0;
						right: 0;
						margin: 0 auto;
						bottom: -6px;
						height: 4px;
						width: 0;
						background: $primary;
						@include border-radius(4px);
						@extend .transition;
						@include breakpoint(max, 1100) {
							left: 0;
							right: inherit;
							display: none;
						}
					}
					&:hover {
						color: $primary;
						&:before {
							width: 30px;
						}
					}
				}
				.toggle-btn {
					display: none;
					border: 0;
					background: transparent;
					position: absolute;
					right: 5px;
					top: 4px;
					align-items: center;
					justify-content: center;
					@include breakpoint(max, 1100) {
						display: flex;
						width: 40px;
						height: 40px;
					}
				}
				.sub-menu {
					position: absolute;
					left: 0;
					top: 100%;
					background: $white;
					margin-top: 20px;
					opacity: 0;
					visibility: hidden;
					@include box-shadow(0px 0px 15px rgba($black, 0.1));
					@include breakpoint(max, 1100) {
						position: relative;
						top: 0;
						margin-top: 0;
						box-shadow: none;
						padding: 0 15px;
						opacity: 1;
						visibility: visible;
					}
					&:before {
						content: "";
						width: 100%;
						height: 20px;
						top: -20px;
						position: absolute;
						left: 0;
						@include breakpoint(max, 1100) {
							display: none;
						}
					}
					&.collapse:not(.show) {
						@include breakpoint(min, 1101) {
							display: block;
							// opacity: 0;
							// visibility: hidden;
						}
					}
					@include breakpoint(min, 1101) {
						@include transition(all 0.3s ease-in-out);
					}
					ul {
						padding: 8px 0;
						@include breakpoint(max, 1100) {
							padding-top: 0;
						}
						li {
							a {
								display: block;
								padding: 8px 20px;
								color: $black;
								white-space: nowrap;
								@extend .transition;
								@include breakpoint(max, 1100) {
									font-size: 15px;
								}
								&:hover {
									color: $primary;
								}
							}
						}
					}
				}
				&:hover {
					.sub-menu {
						opacity: 1;
						visibility: visible;
						// &.collapse:not(.show) {
						// 	@include breakpoint(min, 1101) {
						// 		opacity: 1;
						// 		visibility: visible;
						// 	}
						// }
					}
				}
			}
		}
	}
}
