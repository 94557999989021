@import "../../variables";
@import "../../mixins";

.footer-wrap {
	position: relative;
	padding: 70px 0 20px;
	padding-bottom: 0;
	background: $dark-color;
	@include breakpoint(max, 1024) {
		padding: 60px 0 30px;
	}
	.container {
		max-width: 1310px;
	}
	.footer-column {
		display: flex;
		flex-wrap: wrap;
		margin: 0 - 15px;
		> li {
			width: 25%;
			padding: 0 15px 50px;
			@include breakpoint(max, 1024) {
				padding-bottom: 30px;
			}
			@include breakpoint(max, 991) {
				width: 33.33%;
			}
			@include breakpoint(max, 767) {
				width: 50%;
			}
			h5 {
				@extend .sans-700;
				color: $white;
				font-size: 22px;
				line-height: 32px;
				padding-bottom: 10px;
				@include breakpoint(max, 767) {
					font-size: 20px;
					line-height: 30px;
				}
			}
		}
	}
	.quick-links {
		li {
			padding-bottom: 6px;
			@include breakpoint(max, 991) {
				padding-bottom: 10px;
			}
			a {
				@extend .sans-300;
				font-size: 16px;
				line-height: 26px;
				display: inline-block;
				color: $white;
				@extend .transition;
				@include breakpoint(max, 1400) {
					font-size: 18px;
					line-height: 28px;
				}
				@include breakpoint(max, 991) {
					font-size: 16px;
					line-height: 22px;
				}
				&:hover {
					color: $secondary;
				}
			}
		}
	}
}

.social-icons-wrapper {
	width: 100% !important;
	h5 {
		display: inline;
	}
}

.social-share {
	display: inline;padding-left: 30px;
	ul {
		display: inline-flex;
		padding-left: 30px;
		li {
			margin-right: 14px;
			a {
				display: block;
				color: $white;
				@extend .transition;
				&:hover {
					color: $secondary;
				}
				svg {
					width: 17.5px;
    			height: 20px;
					fill: currentColor;
					vertical-align: bottom;
				}
			}
		}
	}
}

.footer-copyright-sec {
	padding: 30px 0;
	color: #bdbec3;
	border-top: 1px solid rgba(255,255,255,.1);
}

.footer-copyright-sec span{font-size: 16px;font-family: 'museo sans 300';}
.footer-copyright-sec a{color: #0d6efd;}
.footer-wrap{    min-height: calc(100vh - 85px);
	padding-top: 100px;
	color: #fff;
	font-size: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;}
	.footer-wrap .container {
    max-width: 90%;
    padding: 0 30px;
}
.footer-wrap .footer-column{margin: 0 -15px}
.footer-wrap .quick-links li a{font-family: 'museo sans 300';font-size: 16px;}
.footer-wrap .footer-column > li h5{    font-size: 24px;
	padding-bottom: 20px;
	position: relative;
	font-weight: 700;}


@media screen and (max-width: 991px){
	.footer-wrap{    display: block;}
	.footer-wrap .footer-column{display: block;}
	.footer-wrap .container {
    max-width: 100%;
    padding: 0 30px;
	}
	.footer-wrap{padding-top: 70px;}
	.footer-wrap .footer-column > li {
    width: 100% !important;
	}
}