.h1 {
	font-size: 64px;
	color: $dark-color;
	font-weight: 600;
	font-family: $font-family-secondary;
}
.h1.large {
	font-size: 72px;
	font-weight: 600;
	font-family: $font-family-secondary;
	@include breakpoint(max, 1024) {
		font-size: 50px;
	}
	@include breakpoint(max, 767) {
		font-size: 40px;
	}
}
.h2 {
	font-size: 40px;
	line-height: 50px;
	color: $primary;
	@extend .sans-700;
	a {
		color: currentColor;
	}
	@include breakpoint(max, 767) {
		font-size: 30px;
		line-height: 40px;
	}
}
.h3 {
	font-size: 35px;
	line-height: 45px;
	color: $primary;
	@extend .sans-700;
	@include breakpoint(max, 767) {
		font-size: 28px;
		line-height: 38px;
	}
}
.h4 {
	font-size: 28px;
	line-height: 40px;
	color: $dark-color;
	font-weight: 500;
	font-family: $font-family-secondary;
	@include breakpoint(max, 767) {
		font-size: 22px;
	}
}
.h5 {
	font-size: 20px;
	line-height: 28px;
	color: $dark-color;
	font-weight: 500;
	font-family: $font-family-secondary;
}
.h6 {
	font-size: 18px;
	color: $dark-color;
	font-weight: 500;
	font-family: $font-family-secondary;
}

.text-small {
	font-size: 14px;
}
.text-ex-small {
	font-size: 12px;
}
.text-large {
	font-size: 18px;
	line-height: 30px;
}
.text-normal {
	font-size: 16px;
	line-height: 26px;
}
.text-ex-large {
	font-size: 20px;
}

.icon-normal {
	font-size: 24px;
	line-height: 1;
}
.icon-small {
	font-size: 20px;
	line-height: 1;
}
.icon-medium {
	font-size: 32px;
	line-height: 1;
}
.icon-large {
	font-size: 48px;
	line-height: 1;
}
.icon-ex-large {
	font-size: 72px;
	line-height: 1;
}
.fw-medium {
	font-weight: 500;
	font-family: $font-family-secondary;
}

.title-wrap {
	.h2 {
		padding-bottom: 25px;
		@include breakpoint(max, 767) {
			padding-bottom: 15px;
		}
	}
	p {
		font-size: 25px;
		line-height: 31px;
		margin-bottom: 0;
		@include breakpoint(max, 1600) {
			font-size: 20px;
			line-height: 30px;
		}
		@include breakpoint(max, 767) {
			font-size: 18px;
			line-height: 28px;
		}
	}
}

.stop-shop-section{padding-bottom: 70px;}
.stop-shop-section .h2{margin-bottom: 20px;}

@media screen and (max-width: 767px) {
	.stop-shop-section{padding-bottom: 30px;}
	
}